import {
  Button,
  Col,
  Dropdown,
  Popconfirm,
  Row,
  Timeline,
  TimelineItemProps,
  Tooltip,
  Typography,
} from 'antd';
import { InfoCircleOutlined, PlusCircleFilled } from '@ant-design/icons';
import React, { FC, useEffect, useState } from 'react';
import { Field } from '@ynomia/core/dist/blueprint';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { analytics, archiveAsset } from '../../../../services';
import {
  checkIsArchiveAssetEnabled,
  checkIsEditAssetEnabled,
  checkIsStatusUpdateEnabled,
  getAssetManagerFilters,
  getAssetStatusesKeyedById,
  getAssetTypes,
  getAssetTypesById,
  getLayerTypesKeyedById,
  getPageFeatures,
  getProjectUserListKeyedById,
  getScratchProjectCode,
  getSelectedAssetType,
  getTenant,
  getTimeTravelAssetsById,
} from '../../../../selectors';
import { getAssets, setAssetDetailsAssetId } from '../../../../actions';
import AddNewObservation from './AddNewObservation';
import CloseBlueIcon from '../../../../images/close-blue.svg?react';
import { DEFAULT_STATUS_COLOR } from '../../../../config/constants';
import EditAsset from './EditAsset';
import FieldValues from '../../../atoms/FieldValues';
import ObservationItem from './ObservationItem';
import { TimelineIcon } from '../../../atoms';
import { generateAssetObservationList } from '../../../../utils/asset';
import { getContextStores } from '../../../../context';
import { getLayerPath } from '../../../../utils';
import styles from './styles.module.less';
import { useExpandingTimeline } from '../../../../hooks';

dayjs.extend(isSameOrBefore);

interface Props {
  assetId: string;
}

const AssetDetails: FC<Props> = ({ assetId }) => {
  /* Context  */
  const contextStores = getContextStores();
  const {
    layerState, assetState, assetDispatch, layoutDispatch,
  } = contextStores;
  const { lastFetchStartTime } = assetState;
  const { layersKeyedById } = layerState;

  /* Selectors */
  const assetStatusesKeyedById = getAssetStatusesKeyedById(contextStores);
  const selectedAssetType = getSelectedAssetType(contextStores);
  const layerTypesKeyedById = getLayerTypesKeyedById(contextStores);
  const projectUserListKeyedById = getProjectUserListKeyedById(contextStores);
  const assetTypesById = getAssetTypesById(contextStores);
  const timeTravelAssetsById = getTimeTravelAssetsById(contextStores);
  const assetManagerFilters = getAssetManagerFilters(contextStores);
  const isStatusUpdateEnabled = checkIsStatusUpdateEnabled(contextStores);
  const projectCode = getScratchProjectCode(contextStores);
  const assetTypes = getAssetTypes(contextStores);
  const tenant = getTenant(contextStores);
  const { assetActions } = getPageFeatures(contextStores);
  const isEditAssetEnabled = checkIsEditAssetEnabled(contextStores);
  const isDeleteAssetEnabled = checkIsArchiveAssetEnabled(contextStores);

  const { timeTravelDate } = assetManagerFilters;
  const canHaveDestination = selectedAssetType?.can_have_destination;
  const selectedAssetTypeFields = selectedAssetType?.fields;
  const currentAsset = timeTravelAssetsById.get(assetId)!;
  const { destination, observations } = currentAsset || {};
  const layerID = destination?.layerID || null;
  const [twinId] = destination?.twinID || '';
  const layer = layerID ? layersKeyedById.get(layerID) : null;

  const [isAddNewObservation, setAddNewObservation] = useState<boolean>(false);

  useEffect(() => {
    analytics.trackEvent(
      'Asset Details Viewed',
      { asset_type: selectedAssetType?.id },
    );
  }, []);

  const observationList = generateAssetObservationList(
    assetTypesById,
    assetStatusesKeyedById,
    selectedAssetType,
    projectUserListKeyedById,
    observations,
  );

  const expandController = useExpandingTimeline([], 'key', false);

  const layerPath = getLayerPath(layersKeyedById, layer?.id);

  const renderDestinationField = () => {
    const fieldSchema: Partial<Field>[] = [];
    const fieldValues: { [fieldId: string]: any } = {};

    layerPath.forEach((l) => {
      let label = layerTypesKeyedById.get(l.type)?.name || '';
      const isDestination = layer?.type === l.type;
      if (isDestination) label = `${label} (Destination)`;
      fieldSchema.push({
        id: label,
        label,
        fieldType: 'text',
      });
      fieldValues[label] = l.label;
    });

    const twinIDFieldLabel = 'TwinID/Model location Ref';
    fieldSchema.push({
      id: twinIDFieldLabel,
      label: twinIDFieldLabel,
      fieldType: 'text',
    });
    fieldValues[twinIDFieldLabel] = twinId;

    return (
      <FieldValues
        schema={fieldSchema as Field[]}
        values={fieldValues}
        asset={currentAsset}
        display={{ gutter: [16, 16], labelColSpan: 10, valueColSpan: 14 }}
      />
    );
  };

  const getTimelineItems = () => {
    let timelineItems: TimelineItemProps[] = [];

    if (isStatusUpdateEnabled) {
      const tooltip = timeTravelDate
        ? 'Sorry, You can&#39;t add new event while you have Time Travel active'
        : '';
      timelineItems = [{
        color: DEFAULT_STATUS_COLOR,
        key: 'added_to_ynomia',
        dot: (
          <Tooltip title={tooltip}>
            <Button
              size="small"
              type="text"
              shape="circle"
              disabled={!!timeTravelDate}
              onClick={() => setAddNewObservation(!isAddNewObservation)}
              icon={<PlusCircleFilled style={{ fontSize: 16, color: '#999' }} />}
              className="addNewEventHoverOverride"
            />
          </Tooltip>
        ),
        children: <AddNewObservation
          disabled={!!timeTravelDate}
          tooltip={tooltip}
          showModal={setAddNewObservation}
          isModalOpened={isAddNewObservation}
          assetId={assetId}
        />,
      }];
    }

    if (!observations?.length) {
      return [...timelineItems,
        {
          color: DEFAULT_STATUS_COLOR,
          key: 'added_to_ynomia',
          children: (
            <div>
              Added to Ynomia
              <Tooltip
                title={'We don\'t have any history or events for this asset. '
                + 'Data is catured and added in real-time, so check again soon.'}
              >
                <InfoCircleOutlined style={{ marginTop: 4, marginLeft: 4 }} />
              </Tooltip>
            </div>
          ),
        },
      ];
    }
    const observationItems = observationList?.map((observation) => {
      const {
        action,
        fontColor,
        statusColor,
      } = observation;

      const key = JSON.stringify(observation);
      const isOpened = expandController.expandedRowKeys.includes(key);
      return {
        dot: action !== 'NONE' ? (
          <TimelineIcon
            isOpened={isOpened}
            onClick={() => expandController.toggleRowExpanded(key)}
          />
        ) : null,
        key,
        color: statusColor || DEFAULT_STATUS_COLOR,
        children: (
          <div style={{ backgroundColor: '#FAFAFA', color: fontColor || '#000000e0' }}>
            <ObservationItem
              asset={currentAsset}
              expandedKey={key}
              observation={observation}
              expandController={expandController}
            />
          </div>
        ),
      };
    });
    return [...timelineItems, ...observationItems];
  };

  const renderTimeline = () => <Timeline items={getTimelineItems()} />;

  const renderFields = () => (selectedAssetTypeFields ? (
    <FieldValues
      schema={selectedAssetTypeFields}
      values={currentAsset?.fields || {}}
      asset={currentAsset}
      display={{ gutter: [16, 16], labelColSpan: 10, valueColSpan: 14 }}
    />
  ) : <div />);

  const onPressDelete = () => {
    archiveAsset(tenant, projectCode, assetId);
    getAssets(tenant, projectCode, assetTypes, lastFetchStartTime, assetDispatch);
    setAssetDetailsAssetId(null, layoutDispatch);
  };

  const actionItems = [
    ...(isDeleteAssetEnabled ? [{
      key: 'delete',
      label: (
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => onPressDelete()}
        >
          <Typography.Link style={{ color: '#fb434a' }}>
            Delete
          </Typography.Link>
        </Popconfirm>
      ),
    }] : []),
  ];

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <Button
            shape="circle"
            icon={<CloseBlueIcon style={{ marginTop: 1 }} />}
            onClick={() => setAssetDetailsAssetId(null, layoutDispatch)}
          />
          <span
            className={styles.currentAssetLabel}
          >
            {currentAsset?.label?.toUpperCase() || ''}
          </span>
        </div>
        <div style={{ marginRight: 26 }}>
          {isEditAssetEnabled && assetActions
            && (
            <Dropdown.Button menu={{ items: actionItems }}>
              <EditAsset assetId={assetId} />
            </Dropdown.Button>
            )}
        </div>
      </div>
      <Row gutter={[48, 32]} className={styles.assetDetails}>
        <Col xs={24} xl={12} className={styles.columnFieldsContainer}>
          <div className={styles.assetDetailsTitleContainer}>
            <span className={styles.assetDetailsTitle}>Properties</span>
          </div>
          <div style={{ marginTop: -10 }}>{canHaveDestination && renderDestinationField()}</div>
          <div>{renderFields()}</div>
        </Col>
        <Col xs={24} xl={12} style={{ paddingLeft: 24 }}>
          <div className={styles.assetDetailsTitleContainer}>
            <span className={styles.assetDetailsTitle}>History</span>
          </div>
          <div className={styles.timelineContainer}>{renderTimeline()}</div>
        </Col>
      </Row>
    </div>
  );
};

export default AssetDetails;
